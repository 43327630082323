import { useLayoutEffect, useState } from 'react';

export const SIDE = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const useDynamicAlignment = (ref, { initialAlignment = SIDE.LEFT } = {}) => {
  const [alignment, setAlignment] = useState(initialAlignment);

  useLayoutEffect(() => {
    const rect = ref.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    const oppositeAlignmentMap = {
      [SIDE.LEFT]: SIDE.RIGHT,
      [SIDE.RIGHT]: SIDE.LEFT,
    };

    const { left, right } = rect;
    const viewportWidth = window.innerWidth;

    if (left < 0 && right > viewportWidth) {
      return;
    }

    if (alignment === SIDE.LEFT && right > viewportWidth) {
      setAlignment(oppositeAlignmentMap[alignment]);
      return;
    }

    if (alignment === SIDE.RIGHT && left < 0) {
      setAlignment(oppositeAlignmentMap[alignment]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return alignment;
};
