import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './loading-dots.scss';

export function LoadingDots(props) {
  const { className, dotClassName } = props;

  return (
    <span className={classNames('loading-dots mx-0_25 d-inline-flex align-items-center', className)}>
      <span className={classNames('dot', dotClassName)} />
      <span className={classNames('dot mx-0_25', dotClassName)} />
      <span className={classNames('dot', dotClassName)} />
    </span>
  );
}

LoadingDots.propTypes = {
  className: PropTypes.string,
  dotClassName: PropTypes.string,
};

LoadingDots.defaultProps = {
  className: '',
  dotClassName: '',
};
