import React from 'react';
import PropTypes from 'prop-types';
import { AdUnitDefaultProps, AdUnitPropTypes } from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';

import './dfp-tracking.scss';

export function DfpTracking(props) {
  const {
    lineItemId,
    siteServedCreativeId,
    mobile,
    nativeStyle,
    sect8,
    noRefresh,
    refreshable,
    refreshZones,
    customTargeting,
    slotRenderEndListener,
    siteServedAdsUpdater,
    rerenderWhenViewable,
    verticalOffset,
    ...restProps
  } = props;

  if (!lineItemId) {
    return null;
  }
  const adCustomTargeting = {
    cpaid: lineItemId,
    cpacid: siteServedCreativeId,
    sect8,
    ...customTargeting,
  };

  const adUnitProps = mobile
    ? {
        adName: 'mcompanion',
        xs: true,
        sm: true,
      }
    : {
        adName: 'companion',
        md: true,
        lg: true,
        xl: true,
      };

  return (
    <div style={{ position: 'absolute', bottom: 0, top: 0, left: 0, right: 0, zIndex: -1 }} className="dfp-tracking">
      <RenderWhenViewable verticalOffset={verticalOffset}>
        <AdUnit
          position={'0'}
          customTargeting={adCustomTargeting}
          isVisible={false}
          showLabel={false}
          nativeStyle={nativeStyle}
          refreshable={false}
          refreshDisableWhenZipChanged
          rerenderWhenViewable={rerenderWhenViewable}
          slotRenderEndListener={slotRenderEndListener}
          siteServedAdsUpdater={siteServedAdsUpdater}
          {...adUnitProps}
          {...restProps}
        />
      </RenderWhenViewable>
    </div>
  );
}

DfpTracking.propTypes = {
  lineItemId: PropTypes.string,
  siteServedCreativeId: PropTypes.string,
  mobile: PropTypes.bool,
  nativeStyle: PropTypes.string,
  sect8: PropTypes.string,
  position: PropTypes.string,
  customTargeting: AdUnitPropTypes.customTargeting,
  slotRenderEndListener: PropTypes.func,
  siteServedAdsUpdater: PropTypes.number,
  rerenderWhenViewable: PropTypes.bool,
  verticalOffset: PropTypes.string,
};

DfpTracking.defaultProps = {
  lineItemId: null,
  siteServedCreativeId: '',
  mobile: false,
  nativeStyle: '',
  sect8: '',
  position: '',
  customTargeting: AdUnitDefaultProps.customTargeting,
  slotRenderEndListener: null,
  siteServedAdsUpdater: 0,
  rerenderWhenViewable: false,
  verticalOffset: '-20%',
};
