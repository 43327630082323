import React from 'react';
import PropTypes from 'prop-types';

export function ImpressionTracker({ src, className }) {
  if (!src) return null;

  const now = Date.now();
  const size1x1 = { width: '1px', height: '1px' };

  return <img alt="" src={`${src.replace('[timestamp]', now)}`} style={size1x1} className={className} />;
}

ImpressionTracker.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
};

ImpressionTracker.defaultProps = {
  src: '',
  className: undefined,
};
