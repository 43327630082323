import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDynamicAlignment, SIDE } from 'site-modules/shared/components/popover/use-dynamic-alignment';

export const PopoverContent = forwardRef((props, ref) => {
  const { role, targetAlignment, children, className, id, ariaLabel } = props;
  const elementRef = useRef();

  useImperativeHandle(ref, () => elementRef.current, []);

  const alignment = useDynamicAlignment(elementRef, { initialAlignment: targetAlignment });

  return (
    <div
      role={role}
      ref={elementRef}
      className={classnames('pos-a', alignment, className)}
      aria-label={ariaLabel}
      id={id}
    >
      {children}
    </div>
  );
});

PopoverContent.displayName = 'PopoverContent';

PopoverContent.propTypes = {
  role: PropTypes.string,
  targetAlignment: PropTypes.oneOf(Object.values(SIDE)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

PopoverContent.defaultProps = {
  role: 'dialog',
  targetAlignment: SIDE.LEFT,
  className: undefined,
  ariaLabel: undefined,
};
